import React, { useState } from "react"
import { graphql, StaticQuery } from "gatsby"
import styled from "styled-components"
import convert from "../utils/convert"
import Dialog from "./Dialog"

const Svg = styled.svg`
  background-size: contain;
  * {
    fill: transparent;
    cursor: pointer;
    transition: fill 0.2s;
    stroke: #fff;
    stroke-width: 2px;
  }
`

const A = styled.a`
  &:focus,
  &:hover {
    outline: none;
    * {
      cursor: pointer;
      fill: ${({ sold }: { sold: boolean }) => sold
      ? "rgba(199, 19, 19, 0.35)"
      : "rgba(19, 199, 58, 0.35)"};
    }
  }
`

const SvgContainer = styled.div`
  @media(max-width: 1000px) {
    width: 150vw;
    transform: translateX(-25vw);
  }
  @media(max-width: 600px) {
    width: 175vw;
    transform: translateX(-33.33333vw);
  }
`

type ApartmentProps = {
  shown: boolean,
  sold: boolean,
  title: string,
  subtitle: string,
  label: string,
  text: string,
  stats: {
    label: string,
    value: string
  }[],
  svg: string
}

type ApartmentQueryProps = {
  node: {
    id: string,
    frontmatter: ApartmentProps
  }
}

type ApartmentPickerProps = {
  viewBox: string,
  backgroundImage: string,
  className?: string,
  style?: Object,
  data: {
    apartments: { edges: ApartmentQueryProps[] }
  }
}

const ApartmentPicker: React.FC<ApartmentPickerProps> = ({
  viewBox, backgroundImage, className, style, data
}) => {
  const { edges: apartments } = data.apartments
  const [ activeApartment, setActiveApartment ] = useState<ApartmentProps>()

  return (
    <div
      className={`overflow-x-hidden ${className || ""}`}
      style={style}
    >
      <SvgContainer>
        <Svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox={viewBox}
          className="w-full"
          style={{
            backgroundImage: `url('${backgroundImage}')`
          }}
        >
          {apartments.map(({ node: apartment }: ApartmentQueryProps) => (
            <g key={apartment.id}>
              <A
                id={`apartment-picker-unit-${apartment.id}`}
                sold={apartment.frontmatter.sold}
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  setActiveApartment(apartment.frontmatter)
                  if(!window.dataLayer) return
                  dataLayer.push({
                    event: "apartment-picked",
                    apartmentTitle: apartment.frontmatter.title
                  })
                }}
              >
                <g
                  dangerouslySetInnerHTML={{
                    __html: convert(apartment.frontmatter.svg)
                  }}
                />
              </A>
            </g>
          ))}
        </Svg>
      </SvgContainer>

      {activeApartment && (
        <ApartmentDialog
          setActiveApartment={setActiveApartment}
          {...activeApartment}
        />
      )}
    </div>
  )
}

const AptDialog = styled.div`
  max-height: 85vh;
  @media(max-width: 900px) {
    max-height: none;
  }
`

interface ApartmentDialogProps extends ApartmentProps {
  setActiveApartment: Function
}

const ApartmentDialog: React.FC<ApartmentDialogProps> = ({
  sold,
  title,
  subtitle,
  label,
  text,
  stats,
  setActiveApartment
}) => (
  <Dialog
    title={`${title} - ${subtitle}`}
    shown={true}
    onClose={() => setActiveApartment(null)}
    toBody={true}
    style={{ minHeight: "calc(100% - 55px)" }}
    width={350}
  >
    <AptDialog className="flex sm:block bg-white text-black">
      <div
        className="flex-1"
        style={{ minWidth: 278 }}
      >
        <div className="p-8 font-secondary text-9xl leading-none text-center">
          {label}
        </div>

        {sold && (
          <div className="bg-red-500 text-center p-2 text-white uppercase font-semibold text-sm">
            Leiligheten er solgt
          </div>
        )}

        <div className="p-8 border-solid border-t border-b border-gray-300">
          <h2 className="text-base font-semibold mb-0 pb-4">
            Beskrivelse
          </h2>

          {text}
        </div>

        <div className="p-8 sm:border-solid sm:border-b sm:border-gray-300">
          <h2 className="text-base font-semibold mb-0 pb-4">
            Egenskaper
          </h2>

          {stats.map(({ label, value }, index) => (
            <div
              className="flex justify-between"
              key={index}
            >
              <label className="font-semibold text-alt">
                {label}:
              </label>
              <div>
                {value}
              </div>
            </div>
          ))}
        </div>
      </div>
    </AptDialog>
  </Dialog>
)

const apartmentsQuery = graphql`
  query ApartmentsQuery {
    apartments: allMarkdownRemark(
      filter: {
        frontmatter: {
          templateKey: { eq: "apartment" },
          shown: { eq: true }
        }
      }
    ) {
      edges {
        node {
          id
          frontmatter {
            templateKey
            shown
            sold
            title
            subtitle
            label
            text
            stats {
              label
              value
            }
            svg
          }
        }
      }
    }
  }
`

interface ApartmentPickerWrapperProps {
  viewBox: string,
  backgroundImage: string
}

export default (props: ApartmentPickerWrapperProps) => (
  <StaticQuery
    query={apartmentsQuery}
    render={(data) => <ApartmentPicker
      data={data}
      {...props}
    />}
  />
)
