import React from "react"
import { graphql } from "gatsby"
import Image, { ImageProps } from "../components/Image"
import convert from "../utils/convert"
import ApartmentPicker from "../components/ApartmentPicker"

type LeilighetenePageTemplateProps = {
  start: {
    image: ImageProps,
    title: string,
    text: string
  },
  apartments: {
    pickerViewBox: string,
    pickerBg: ImageProps["src"]
  },
  fasade: {
    title: string,
    text1: string,
    image: ImageProps,
    text2: string
  },
  parken: {
    title: string,
    text1: string,
    image: ImageProps,
    gallery: ImageProps[],
    text2: string
  }
}

const LeilighetenePageTemplate: React.FC<LeilighetenePageTemplateProps> = ({
  start, apartments, fasade, parken
}) => {
  return (
    <main id="leilighetene-page">
      <Image
        src={start.image.src}
        alt={start.image.alt}
        className="h-screen-66 xl:h-auto object-cover object-center mx-auto w-full"
        loading="eager"
        fadeIn={false}
      />

      <section
        id="start"
        className="py-16 sm:py-8"
      >
        <div className="mx-auto w-full max-w-3xl text-center">
          <h1 className="title">
            {start.title}
          </h1>

          <hr className="my-8 w-8 sm:my-5 pt-px bg-alt h-px mx-auto" />

          <div
            className="markdown font-semibold text-alt max-w-2xl mx-auto"
            dangerouslySetInnerHTML={{
              __html: convert(start.text)
            }}
          />
        </div>
      </section>

      <section
        id="leilighetene"
        className="my-16 sm:my-8"
      >
        <ApartmentPicker
          viewBox={apartments.pickerViewBox}
          backgroundImage={apartments.pickerBg}
        />
      </section>

      <section
        id="fasade"
        className="py-16 sm:py-8"
      >
        <div className="mx-auto w-full max-w-2xl text-center">
          <div className="mx-auto w-full max-w-xl px-6">
            <h2 className="subtitle">
              {fasade.title}
            </h2>
          </div>

          <hr className="my-8 w-8 sm:my-5 pt-px bg-alt h-px mx-auto" />

          <div
            className="markdown font-semibold text-alt xs:text-left"
            dangerouslySetInnerHTML={{
              __html: convert(fasade.text1)
            }}
          />
        </div>

        <div className="my-16 mx-auto w-full max-w-8xl sm:my-8">
          <Image
            className="w-full"
            src={fasade.image.src}
            alt={fasade.image.alt}
          />
        </div>

        <div
          className="mx-auto w-full max-w-5xl markdown cc-3"
          dangerouslySetInnerHTML={{
            __html: convert(fasade.text2)
          }}
        />
      </section>

      <section
        id="parken"
        className="pt-16 pb-32 sm:pt-8 sm:pb-16"
      >
        <Image
          className="w-full object-cover object-top"
          style={{ maxHeight: "66vh" }}
          src={parken.image.src}
          alt={parken.image.alt}
        />

        <div className="mx-auto w-full max-w-2xl text-center my-16 sm:my-8">
          <div className="mx-auto w-full max-w-xl px-6">
            <h2 className="subtitle">
              {parken.title}
            </h2>
          </div>

          <hr className="my-8 w-8 sm:my-5 pt-px bg-alt h-px mx-auto" />

          <div
            className="markdown font-semibold text-alt xs:text-left"
            dangerouslySetInnerHTML={{
              __html: convert(parken.text1)
            }}
          />
        </div>

        <div className="grid grid-columns-4 grid-gap-8 lg:grid-gap-4 sm:grid-gap-4 sm:grid-columns-2">
          {parken.gallery.map((image, index) => (
            <div
              key={index}
              className={`
                ${index === 0 ? "row-span-3 sm:row-span-2" : ""}
                ${index === 2 ? "row-span-2 sm:row-span-1" : ""}
                ${index === 0 ? "col-span-3 sm:col-span-2" : ""}
              `}
            >
              <Image
                className="w-full object-cover object-center h-full"
                src={image.src}
                alt={image.alt}
              />
            </div>
          ))}
        </div>

        <div className="mx-auto w-full max-w-3xl mt-16 sm:mt-8">
          <hr className="my-8 w-8 sm:my-5 pt-px bg-alt h-px mx-auto" />

          <div
            className="markdown cc-2"
            dangerouslySetInnerHTML={{
              __html: convert(parken.text2)
            }}
          />
        </div>
      </section>
    </main>
  )
}

interface LeilighetenePageProps {
  data: {
    markdownRemark: {
      frontmatter: LeilighetenePageTemplateProps
    }
  }
}

const LeilighetenePage: React.FC<LeilighetenePageProps> = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  return (
    <LeilighetenePageTemplate
      start={frontmatter.start}
      apartments={frontmatter.apartments}
      fasade={frontmatter.fasade}
      parken={frontmatter.parken}
    />
  )
}

export default LeilighetenePage

export const leilighetenePageQuery = graphql`
  query LeilighetenePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {

        start {
          image {
            src
            alt
          }
          title
          text
        }

        apartments {
          pickerViewBox
          pickerBg
        }

        fasade {
          title
          text1
          image {
            src
            alt
          }
          text2
        }

        parken {
          title
          text1
          image {
            src
            alt
          }
          gallery {
            src
            alt
          }
          text2
        }

      }
    }
  }
`
